import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./style.scss";
import Carousel from "react-bootstrap/Carousel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import section1 from "../../assets/images/home/section1.png";
import section1_2 from "../../assets/images/webp/section1_1zon.jpg";
import section1_1 from "../../assets/images/webp/section1_1_1zon.jpg";
import section2 from "../../assets/images/webp/section2.webp";
import section2p1 from "../../assets/images/webp/section2-p1zon.webp";
import section2p2 from "../../assets/images/webp/section2-p2zon.webp";
import section2p3 from "../../assets/images/webp/section2-p3zon.webp";
import section2p4 from "../../assets/images/webp/section2-p4zon.webp";
import section3 from "../../assets/images/webp/section3-1.webp";
import section3gif from "../../assets/images/home/section3-2.webp";
import section3last from "../../assets/images/webp/section3-3.webp";
import section4 from "../../assets/images/webp/section4zon.webp";
import section5 from "../../assets/images/webp/section5.webp";
import section6 from "../../assets/images/webp/section6.webp";
import section7 from "../../assets/images/webp/section7.webp";
import section81 from "../../assets/images/webp/section8-1.webp";
import section82 from "../../assets/images/webp/section8-2.webp";
import section83 from "../../assets/images/webp/section8-3.webp";
import section84 from "../../assets/images/webp/section8-4.webp";
import section9 from "../../assets/images/webp/section9zon.webp";
import section10 from "../../assets/images/webp/section10.webp";
import section11 from "../../assets/images/webp/section11.webp";
import { SPECIFIC_PAGE_DATA } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { useDispatch } from "react-redux";
import ReactPlayer from "react-player";


function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    apiClient().get(`/mainone`)
        .then((res) => {
            dispatch({type: SPECIFIC_PAGE_DATA, payload:res.data.data})
        })
  }, []);
  

  return (
    <div className="true-home">
      <section className="image-section">
        <img width="600" height="400" src={section1_1} alt="Section 1" />
      </section>

      <section className="video-section">
        <iframe
          src="https://player.vimeo.com/video/1008765253"
          width="600"
          height="300"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="Vimeo Video"
        ></iframe>
      </section>

      {/* <section className="video-section">
        <ReactPlayer
          url="https://vimeo.com/1008765253"  // Your Vimeo video link
          width="600px"
          height="400px"
          controls={true}  // Enables play/pause controls
        />
      </section> */}

      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>

      <section className="image-section">
        <img width="600" height="400" src={section1_2} alt="Section 1_2" />
      </section>

      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>

      <section className="image-section">
        <img width="600" height="400" src={section2} alt="Section 2" />
      </section>

      <div className="image-gallery">
        <div className="large-screen">
          {[section2p1, section2p2, section2p3, section2p4].map((src, index) => (
            <img width="600" height="400" key={index} src={src} alt={`section2p${index + 1}`} />
          ))}
        </div>
        <div className="small-screen2">
          <Carousel>
            {[section2p1, section2p2, section2p3, section2p4].map((src, index) => (
              <Carousel.Item key={index}>
                <img width="auto" height="auto" src={src} alt={`section2p${index + 1}`} className="d-block" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>

      <section className="image-section">
        <img width="600" height="400" src={section3} alt="Section 3" />
      </section>

      <section className="image-section">
        <img width="600" height="400" src={section3gif} alt="Section 3 GIF" />
      </section>

      <section className="image-section">
        <img width="600" height="400" src={section3last} alt="Section 3 Last" />
      </section>
          
      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>

      <section className="image-section">
        <img width="600" height="400" src={section4} alt="Section 4" />
      </section>

      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>

      <section className="image-section">
        <img width="600" height="400" src={section5} alt="Section 5" />
      </section>

      <section className="image-section">
        <img width="600" height="400" src={section6} alt="Section 6" />
      </section>

      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>

      <section className="image-section">
        <img width="600" height="400" src={section7} alt="Section 7" />
      </section>

      <div className="image-gallery">
        <div className="large-screen">
          {[section81, section82, section83, section84].map((src, index) => (
            <img width="600" height="400" key={index} src={src} alt={`section8${index + 1}`} />
          ))}
        </div>
        <div className="small-screen2">
          <Carousel>
            {[section81, section82, section83, section84].map((src, index) => (
              <Carousel.Item key={index}>
                <img width="600" height="400" src={src} alt={`section8${index + 1}`} className="d-block" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>

      <section className="image-section">
        <img width="600" height="400" src={section9} alt="Section 9" />
      </section>

      <Link to="/checkout" className="buy-button">
        <ShoppingCartIcon />
        <b><span>Acheter Maintenant: 1000 dhs au lieu de</span> <strike>2000 dhs</strike> </b>
      </Link>
      
      <section className="image-section">
        <img width="600" height="400" src={section10} alt="Section 10" />
      </section>

      <section className="image-section">
        <img width="600" height="400" src={section11} alt="Section 11" />
      </section>
    </div>
  );
}

export default Home;