import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { CustomRoute } from "../components";
import { 
    
    AboutPage,
    CguPage,
    CheckoutPage,
    ContactPage,
    CoursePage,
    Home,
    MainPage,
    NotFoundPage,
    Politique,
    SuccessPage,
} from "../pages";
import ScrollToTop from "../components/scrolltotop";

const MainNavigator = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route
                    path="/"
                    element={
                    <CustomRoute path="/" showfooter homefooter component={Home} />
                }
                />
                <Route
                    path="/contactus"
                    element={
                    <CustomRoute path="/contactus" showheader showfooter component={ContactPage} />
                }
                />
                <Route
                    path="/about"
                    element={
                    <CustomRoute path="/about" showheader showfooter component={AboutPage} />
                }
                />
                <Route
                    path="/course"
                    element={
                    <CustomRoute path="/course" showheader showfooter component={CoursePage} />
                }
                />
                <Route
                    path="/checkout"
                    element={
                    <CustomRoute path="/checkout" showheader showfooter component={CheckoutPage} />
                }
                />
                <Route
                    path="/politique"
                    element={
                    <CustomRoute path="/politique" showheader showfooter component={Politique} />
                }
                />
                <Route
                    path="/cgu"
                    element={
                    <CustomRoute path="/cgu" showheader showfooter component={CguPage} />
                }
                />
                <Route
                    path="/success"
                    element={
                    <CustomRoute path="/success" showheader showfooter component={SuccessPage} />
                }
                />
                {/* <Route 
                    path="/home"
                    element={
                    <CustomRoute path="/home" showfooter homefooter component={Home} />
                } 
                />*/}
                <Route 
                    path="*"
                    element={
                    <CustomRoute path="*" showheader showfooter component={NotFoundPage} />
                }
                />
            </Routes>
        </Router>
    );
}

export default MainNavigator;
